<template>
  <div>
    <transition>
      <router-view/>
    </transition>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
